/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Dispatch } from 'redux'
import {
  GET_BY_ID_ACCOUNT_CLASS_SUCCESS,
  GET_BY_ID_ACCOUNT_CLASS_ERROR,
  UPDATE_ACCOUNT_CLASS_SUCCESS,
  UPDATE_ACCOUNT_CLASS_ERROR,
  CREATE_ACCOUNT_CLASS_SUCCESS,
  CREATE_ACCOUNT_CLASS_ERROR,
  GET_LIST_SERIES_BY_ACCT_CLASSES,
  GET_LIST_SERIES_BY_ACCT_CLASSES_SUCCESS,
  DELETE_ACCOUNT_CLASS_SUCCESS,
  DELETE_ACCOUNT_CLASS,
  DELETE_ACCOUNT_CLASS_ERROR,
} from '../../../constants/ActionTypes'
import {
  STATUS_CODE_SUCCESS,
  STATUS_CODE_CREATE_SUCCESS,
} from '../../../constants/StatusCode'
import { AccountClassApi } from '../../../client-axios/api'
import { configHeader } from '../../../core/token'
import { axiosInstance } from '../../../core/axios'
import { message } from 'antd'
import { capitalizeFirstLetter } from '../../../util/function'
export const getListAccountClass = (): unknown => {
  const accountClasssApi = new AccountClassApi(
    configHeader(),
    undefined,
    axiosInstance
  )
  return async () => {
    try {
      return accountClasssApi.findAllWithAccountClasses().then((res) => {
        if (res?.status === STATUS_CODE_SUCCESS) {
          return res
        } else {
          return res
        }
      })
    } catch (error) {
      message.error((error as any)?.message)
    }
  }
}
export const createAccountClass =
  (payload: any): unknown =>
  (dispatch: Dispatch) => {
    const accountClasssApi = new AccountClassApi(
      configHeader(),
      undefined,
      axiosInstance
    )
    return accountClasssApi
      .createWithAccountClasses(payload)
      .then((res) => {
        if (res.status === STATUS_CODE_CREATE_SUCCESS) {
          dispatch({ type: CREATE_ACCOUNT_CLASS_SUCCESS, payload: res.data })
        }
        return res?.status
      })
      .catch((error) => {
        if (error.response) {
          dispatch({
            type: CREATE_ACCOUNT_CLASS_ERROR,
            error: error.response?.data?.detail?.message,
          })
          message.error(capitalizeFirstLetter(error.response?.data?.detail?.message.replace(/_/g, ' ')), 10)
        }
        return error?.response?.statusCode
      })
  }
export const updateAccountClass =
  (payload: any): unknown =>
  (dispatch: Dispatch) => {
    const accountClasssApi = new AccountClassApi(
      configHeader(),
      undefined,
      axiosInstance
    )
    return accountClasssApi
      .updateWithAccountClasses(payload.id, payload.data)
      .then((res) => {
        if (res?.status === STATUS_CODE_SUCCESS) {
          dispatch({ type: UPDATE_ACCOUNT_CLASS_SUCCESS, payload: res.data })
        }
        return res?.status
      })
      .catch((error) => {
        if (error?.response) {
          dispatch({
            type: UPDATE_ACCOUNT_CLASS_ERROR,
            error: error.response?.data?.detail?.message,
          })
          message.error(capitalizeFirstLetter(error.response?.data?.detail?.message.replace(/_/g, ' ')), 10)
        }
        return error.response?.data?.statusCode
      })
  }
export const deleteAccountClass = (payload: any) => (dispatch: Dispatch) => {
  const accountClasssApi = new AccountClassApi(
    configHeader(),
    undefined,
    axiosInstance
  )
  dispatch({ type: DELETE_ACCOUNT_CLASS })
  return accountClasssApi
    .removeWithAccountClasses(payload.id)
    .then((res) => {
      if (res?.status === STATUS_CODE_SUCCESS) {
        dispatch({ type: DELETE_ACCOUNT_CLASS_SUCCESS, data: res.data })
      }
    })
    .catch((error) => {
      if (error.response) {
        dispatch({ type: DELETE_ACCOUNT_CLASS_ERROR })
        return message.error('Cannot delete, Series are existing in Class!')
      }
    })
}
export const getAccountClassById =
  (payload: any): unknown =>
  (dispatch: Dispatch) => {
    const accountClasssApi = new AccountClassApi(
      configHeader(),
      undefined,
      axiosInstance
    )
    return accountClasssApi
      .findOneWithAccountClasses(payload.id)
      .then((res) => {
        if (res?.status === STATUS_CODE_SUCCESS) {
          dispatch({ type: GET_BY_ID_ACCOUNT_CLASS_SUCCESS, payload: res.data })
        }
      })
      .catch((error) => {
        dispatch({
          type: GET_BY_ID_ACCOUNT_CLASS_ERROR,
          error: error.response?.data?.detail?.message,
        })
      })
  }
export const getAccountSeriesByClassId =
  (payload: any) => (dispatch: Dispatch) => {
    dispatch({ type: GET_LIST_SERIES_BY_ACCT_CLASSES })
    const accountClasssApi = new AccountClassApi(
      configHeader(),
      undefined,
      axiosInstance
    )
    return accountClasssApi
      .findSeriesByAccountClassWithAccountClasses(payload.id)
      .then((res) => {
        if (res?.status === STATUS_CODE_SUCCESS) {
          dispatch({
            type: GET_LIST_SERIES_BY_ACCT_CLASSES_SUCCESS,
            data: res.data,
          })
        }
      })
  }

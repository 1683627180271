/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Dispatch } from 'redux'
import {
  GET_LIST_INVESTOR,
  GET_LIST_INVESTOR_SUCCESS,
  GET_LIST_INVESTOR_ERROR,
  GET_BY_ID_INVESTOR_SUCCESS,
  GET_BY_ID_INVESTOR_ERROR,
  CREATE_INVESTOR_SUCCESS,
  CREATE_INVESTOR_ERROR,
  GET_LIST_INVESTOR_DEFAULT,
  UPDATE_INVESTOR_SUCCESS,
  UPDATE_INVESTOR_ERROR,
  UPDATE_INVESTOR,
  GET_LIST_INVESTOR_ACTIVE,
} from '../../../constants/ActionTypes'
import {
  STATUS_CODE_SUCCESS,
  STATUS_CODE_CREATE_SUCCESS,
} from '../../../constants/StatusCode'
import { InvestorsApi } from '../../../client-axios/api'
import { configHeader } from '../../../core/token'
import { axiosInstance } from '../../../core/axios'
import { message } from 'antd'
import { capitalizeFirstLetter } from '../../../util/function'
export const getListInvestorMaster = (payload: { withDivision?: boolean }): unknown => (dispatch: Dispatch) => {
  const investorsApi = new InvestorsApi(
    configHeader(),
    undefined,
    axiosInstance
  )
  dispatch({ type: GET_LIST_INVESTOR })
  investorsApi
    .findAllWithInvestors(payload.withDivision)
    .then((res) => {
      if (res?.status === STATUS_CODE_SUCCESS) {
        dispatch({ type: GET_LIST_INVESTOR_SUCCESS, data: res.data })
        dispatch({ type: GET_LIST_INVESTOR_ACTIVE, data: res.data })
        dispatch({ type: GET_LIST_INVESTOR_DEFAULT, data: res.data })
      }
    })
    .catch((error) => {
      dispatch({
        type: GET_LIST_INVESTOR_ERROR,
        error: error.response?.data?.detail?.message,
      })
    })
}
export const createInvestor =
  (payload: any): unknown =>
    (dispatch: Dispatch) => {
      const investorsApi = new InvestorsApi(
        configHeader(),
        undefined,
        axiosInstance
      )
      return investorsApi
        .createWithInvestors(payload)
        .then((res) => {
          if (res.status === STATUS_CODE_CREATE_SUCCESS) {
            dispatch({ type: CREATE_INVESTOR_SUCCESS, payload: res.data })
          }
          return res?.status
        })
        .catch((error) => {
          dispatch({
            type: CREATE_INVESTOR_ERROR,
            error: error.response?.data?.detail?.message,
          })
          message.error(error.response?.data?.detail?.message)
          return error?.response?.statusCode
        })
    }
export const updateInvestor =
  (payload: { id: number; data: any }): unknown =>
    (dispatch: Dispatch) => {
      const investorsApi = new InvestorsApi(
        configHeader(),
        undefined,
        axiosInstance
      )
      dispatch({ type: UPDATE_INVESTOR })
      return investorsApi
        .updateWithInvestors(payload.id, payload.data)
        .then((res) => {
          if (res.status === STATUS_CODE_CREATE_SUCCESS) {
            dispatch({ type: UPDATE_INVESTOR_SUCCESS, payload: res.data })
          }
          return res?.status
        })
        .catch((error) => {
          dispatch({
            type: UPDATE_INVESTOR_ERROR,
            error: error.response?.data?.detail?.message,
          })
          message.error(capitalizeFirstLetter(error.response?.data?.detail?.message.replace(/_/g, ' ')), 10)
          return error?.response?.statusCode
        })
    }
export const getInvestorById =
  (payload: { id: any, withDivision?: boolean }): unknown =>
    (dispatch: Dispatch) => {
      const investorsApi = new InvestorsApi(
        configHeader(),
        undefined,
        axiosInstance
      )
      // dispatch({ type: GET_BY_ID_INVESTOR })
      return investorsApi
        .findOneWithInvestors(payload.id, payload.withDivision)
        .then((res) => {
          if (res?.status === STATUS_CODE_SUCCESS) {
            dispatch({ type: GET_BY_ID_INVESTOR_SUCCESS, payload: res?.data })
          }
        })
        .catch((error) => {
          dispatch({
            type: GET_BY_ID_INVESTOR_ERROR,
            error: error.response?.data?.detail?.message,
          })
        })
    }
